



































































import { Vue, Emit } from "vue-property-decorator";

export default class SupportingDocuments extends Vue {
  file: File = {} as File;
  _onChangeFile($event: any) {
    const chosenfFile = $event.target.files[0];
    this.file = chosenfFile;
  }
  @Emit("getValue")
  created() {
    return 4;
  }
}
